<template>
	<div>
		<div class="top"><div class="title">商城积分</div></div>
		<div class="list">
			<table border="0" cellspacing="0" cellpadding="0">
				<tr class="list-title">
					<th width="150">用户编号</th>
					<th width="150">订单编号</th>
					<th width="150">成交金额</th>
					<th width="150">下单时间</th>
					<th width="150">获得积分</th>
					<th width="150">获得途径</th>
					<th width="150">到账状态</th>
				</tr>
			</table>
		</div>
		<div class="list-city" v-for="(item, index) in list" :key="index">
			<table border="0" cellspacing="0" cellpadding="0">
				<tr>
					<td width="150"><div style="width: 130px;margin-left:10px;text-align: left;">{{ item.UserID }}</div></td>
					<td width="150">{{ item.OrderID }}</td>
					<td width="150">{{ item.TotalPrice }}</td>
					<td width="150">{{ item.CreatTime }}</td>
					<td width="150">{{ item.Point }}</td>
					<td width="150">{{ item.title }}</td>

					<td width="150">
						<span v-if="item.PushState != 1" style="color: #F56C6C;">待入账</span>
						<span v-if="item.PushState == 1" style="color:#0097ba">已入账</span>
					</td>
				</tr>
			</table>
		</div>
		<div class="pagination">
			<div>
				<el-pagination
					background
					@size-change="changeSize"
					@current-change="changeCurrent"
					:page-sizes="[10, 50, 100, 200]"
					:page-size="20"
					layout="total, sizes, prev, pager, next, jumper"
					:total="count"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { MyPointList } from './service';

export default {
	data() {
		return {
			list: [],
			count: 0,
			parameter: {
				PageIndex: 1,
				PageSize: 10
			}
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		changeSize(size) {
			this.parameter.PageSize = size;
			this.init();
		},
		changeCurrent(size) {
			this.parameter.PageIndex = size;
			this.init();
		},
		init() {
			MyPointList(this.parameter).then(res => {
				this.list = res.data;
				this.count = res.count;
			});
		}
	}
};
</script>

<style scoped lang="less">
.list-city {
	border: 1px solid #f4f4f4;
	padding: 20px 0;
	font-size: 13px;
	color: #333333;
	td {
		text-align: center;
	}
	.handle {
		cursor: pointer;
	}
	.m {
		margin: 0 5px;
	}
	.active {
		background: rgba(27, 152, 170, 0.2);
		padding: 2px 20px;
		color: #1b98aa;
	}
}
.pagination {
	display: flex;
	margin-top: 30px;
	justify-content: flex-end;
	margin-right: 30px;
}
.top {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #dcdcdc;
	padding-bottom: 5px;

	.title {
		font-size: 18px;
		font-family: 'ct';
	}
}

.list {
	margin-top: 20px;
}

.list-title {
	background: #eeeeee;
	height: 40px;
	text-align: center;
}
</style>
