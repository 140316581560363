import {post} from '@/http'


export function MyPointList(data) {
    return new Promise((resolve, reject) => {
        post('/Users/MyPointList', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}






